import {apiConstructor} from "@/api";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default fiscaliteCapitalApi;

function fiscaliteCapitalApi() {
  return {
    ifu2561terAssociePDF: getIfu2561TerAssociePDF(),
    pfu2777Infos: getPfu2777Infos(),
    pfu2777PDF: getPfu2777PDF(),
    listeMinisterielle: getListeMinisterielle(),
  };
}

function getIfu2561TerAssociePDF() {
  function ifu2561terAssociePDF(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.fiscalite_capital.ifu2561terAssociePDF();
    let getForPath = apiConstructor.get(path);
    queryParams = queryParams || {};
    queryParams.exercice_id = exerciceId;
    return getForPath(queryParams, headers);
  }

  return ifu2561terAssociePDF;
}

function getPfu2777Infos() {
  function pfu2777Infos(exerciceId, versionCerfa, queryParams, headers) {
    let path = internalApiRoutes.fiscalite_capital.pfu2777Infos();
    let getForPath = apiConstructor.get(path);
    queryParams = queryParams || {};
    queryParams.exercice_id = exerciceId;
    queryParams.nom_version_cerfa = versionCerfa;
    return getForPath(queryParams, headers);
  }

  return pfu2777Infos;
}

function getPfu2777PDF() {
  function pfu2777PDF(exerciceId, versionCerfa, queryParams, headers) {
    let path = internalApiRoutes.fiscalite_capital.pfu2777PDF();
    let getForPath = apiConstructor.get(path);
    queryParams = queryParams || {};
    queryParams.exercice_id = exerciceId;
    queryParams.nom_version_cerfa = versionCerfa;
    return getForPath(queryParams, headers);
  }

  return pfu2777PDF;
}

function getListeMinisterielle() {
  function listeMinisterielle(exerciceId, queryParams, headers) {
    let path = internalApiRoutes.fiscalite_capital.listeMinisterielle(exerciceId);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers);
  }

  return listeMinisterielle;
}
