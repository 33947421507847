import {apiConstructor} from "@/api";
import defaultApi, {ACCEPTED_METHODS} from "../../defaultApi";
import internalApiRoutes from "@/api/internalApi/apiRoutes";

export default cerfa2561Api;

function cerfa2561Api() {
  const specificApi = {
    getDataTiersTotauxFromAnneeFiscale: getDataTiersTotauxFromAnneeFiscale(),
    getDataTiersFromAnneeFiscale: getDataTiersFromAnneeFiscale(),
    downloadCerfa2561: downloadCerfa2561(),
    downloadCerfa2561ter: downloadCerfa2561ter(),
    downloadFichierTeletransmission: downloadFichierTeletransmission(),
  };
  return Object.assign(specificApi, defaultApi(
    internalApiRoutes.documentsLegaux.cerfa2561.baseUrl,
    []
  ));
}

function getDataTiersTotauxFromAnneeFiscale() {
  function getDataTiersTotauxFromAnneeFiscale(anneeFiscale, queryParams, headers) {
    let path = internalApiRoutes.documentsLegaux.cerfa2561.getDataTiersTotauxCerfa2561FromAnneeFiscale(anneeFiscale);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getDataTiersTotauxFromAnneeFiscale;
}

function getDataTiersFromAnneeFiscale() {
  function getDataTiersFromAnneeFiscale(anneeFiscale, queryParams, headers) {
    let path = internalApiRoutes.documentsLegaux.cerfa2561.getDataTiersCerfa2561FromAnneeFiscale(anneeFiscale);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return getDataTiersFromAnneeFiscale;
}

function downloadCerfa2561() {
  function downloadCerfa2561(anneeFiscale, queryParams, headers) {
    let path = internalApiRoutes.documentsLegaux.cerfa2561.downloadCerfa2561(anneeFiscale);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return downloadCerfa2561;
}

function downloadCerfa2561ter() {
  function downloadCerfa2561ter(anneeFiscale, queryParams, headers) {
    let path = internalApiRoutes.documentsLegaux.cerfa2561.downloadCerfa2561ter(anneeFiscale);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return downloadCerfa2561ter;
}

function downloadFichierTeletransmission() {
  function downloadFichierTeletransmission(anneeFiscale, queryParams, headers) {
    let path = internalApiRoutes.documentsLegaux.cerfa2561.downloadFichierTeletransmission(anneeFiscale);
    let getForPath = apiConstructor.get(path);
    return getForPath(queryParams, headers).then(result => {
      return Promise.resolve(result);
    });
  }

  return downloadFichierTeletransmission;
}
